:root {
  --primary-family: Poppins, sans-serif;
}

// Font Sizes
:root {
  --font-size-xxsmall: 8px;
  --font-size-xsmall: 10px;
  --font-size-smaller: 12px;
  --font-size-small: 14px;
  --font-size-regular: 16px;
  --font-size-bigger: 18px;
  --font-size-xbigger: 20px;
  --font-size-xxbigger: 22px;
  --font-size-large: 24px;
  --font-size-xlarge: 34px;
  --font-size-h1: 48px;
  --font-size-h2: 42px;
  --font-size-h3: 38px;
}

// Font Weights
:root {
  --regular-weight: 400;
  --medium-weight: 500;
  --semiBold-weight: 600;
  --bold-weight: 700;
}

:root {
  font-family: var(--primary-family);
  font-size: var(--font-size-regular);
  font-weight: var(--regular-weight);
}
