:root {
  --primary-color: #474f61;
  --primary-color-lighter: #dee9fe;
  --primary-color-light: #333;
  --primary-color-dark: #3d4556;
  --primary-color-faded: #b4bed5;
  --primary-color-highlighted: #474f61;
  --secondary-color-light: #00c5ff;
  --secondary-color: #0e55df;
  --tertiary-color: #d200a5;
  --special-color: #008000;
  --color-white: #fff;
  --grey-color: #eaecf1;
  --grey-color-darker: #aab6ce;
  --color-error: #f50000;
  --transpatent: transparent;
}

:root {
  color: var(--primary-color);
}
