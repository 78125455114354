* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  :focus {
    outline: none;
  }
}

html {
  background-color: white;
  font-size: 100%;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: white;
}

html[data-scroll-lock='true'],
html[data-scroll-lock='true'] body {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
}

a {
  color: currentColor;
  text-decoration: none;
}

dl,
ol,
ul {
  list-style-type: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 100%;
  touch-action: manipulation;
  user-select: none;
  -webkit-appearance: none;
}

button:disabled {
  cursor: default;
  touch-action: none;
}
